import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-polyglot";
import { useBaseData } from "../Providers/Data/BaseDataProvider.jsx";
import FlexboxVertical from "./FlexboxVertical.jsx";

const useStyles = makeStyles(() => ({
  dialogRoot: {
    paddingTop: 0,
    padding: 10,
    "&:first-child": {
      paddingTop: 0,
    },
  },
  iframe: {
    width: "100%",
    height: "calc(100vh - 200px)",
    marginBottom: 20,
    border: "none",
  },
}));

/** */
const PaymentDialog = ({ isDialogOpened, handleCloseDialog }) => {
  const classes = useStyles();
  const { payHtml, clearPayHtml, chooseSubject, preselectedSubject } = useBaseData();
  const t = useTranslate();

  /** */
  const closeDialogPreHandler = async () => {
    clearPayHtml();
    handleCloseDialog();

    try {
      await chooseSubject(preselectedSubject);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (payHtml && payHtml.type === "stripe") {
      window.location.href = payHtml.data;
    }
  }, [payHtml]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isDialogOpened && !!payHtml && payHtml.type !== "stripe"}
      onClose={closeDialogPreHandler}
    >
      <DialogContent className={classes.dialogRoot}>
        <FlexboxVertical data-component="PayWebview" alignItems="center">
          <iframe className={classes.iframe} src={payHtml?.data} />
          <Button variant="contained" color="primary" onClick={closeDialogPreHandler}>
            {t("closeButton")}
          </Button>
        </FlexboxVertical>
      </DialogContent>
    </Dialog>
  );
};

PaymentDialog.propTypes = {
  isDialogOpened: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};

export default PaymentDialog;
