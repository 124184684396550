import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import PaymentDialog from "../../SharedComponents/PaymentDialog.jsx";
import { useBaseData } from "../Data/BaseDataProvider.jsx";
import { useDialogs } from "../Common/DialogsProvider.jsx";
import { useAuth } from "../Auth/AuthProvider.jsx";

const PaymentContext = React.createContext(null);

/**
 * @returns {PaymentContext}
 */
export const usePayment = () => useContext(PaymentContext);

// eslint-disable-next-line require-jsdoc
const PaymentProvider = ({ children }) => {
  const t = useTranslate();
  const { requestPaymentHtml, preselectedOffer, setPreselectedOffer, userInfo } = useBaseData();
  const { pathname } = useLocation();
  const snackbar = useDialogs();
  const { user } = useAuth();

  const [isDialogOpened, setIsDialogOpened] = useState(false);

  /** */
  const pay = (id) => {
    if (userInfo) {
      const existOffer = userInfo && userInfo.offers.find((offer) => offer.status === "pending");

      if (existOffer && !existOffer.paid && existOffer.offerId !== id) {
        return snackbar.warn(`${t("existOffer")}`);
      }
    }

    setIsDialogOpened(true);
    requestPaymentHtml(id, "offer").then();
  };

  /** opening payment first time after login or register if offer was chosen */
  useEffect(() => {
    if (user && preselectedOffer) {
      pay(preselectedOffer);
      setPreselectedOffer(null);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedOffer, pathname]);

  return (
    <PaymentContext.Provider value={{ pay }}>
      {children}
      {isDialogOpened && (
        <PaymentDialog isDialogOpened={isDialogOpened} handleCloseDialog={() => setIsDialogOpened(false)} />
      )}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
